<template>
  <b-img
    :src="require('@/assets/images/logo/logo.png')"
    width="32"
  />
</template>

<script>
  import { BImg } from 'bootstrap-vue';

  export default {
    components: {
      BImg,
    },
    setup() {},
  };
</script>
