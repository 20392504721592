<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">404</h2>
        <p class="mb-2">페이지를 찾을 수 없습니다.</p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          첫페이지로 이동
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
  /* eslint-disable global-require */
  import { BLink, BButton, BImg } from 'bootstrap-vue';
  import VuexyLogo from '@core/layouts/components/Logo.vue';
  import store from '@/store/index';
  import { $themeConfig } from '@themeConfig';
  export default {
    components: {
      VuexyLogo,
      BLink,
      BButton,
      BImg,
    },
    data() {
      const { appName, appLogoImage } = $themeConfig.app;
      return {
        appName,
        appLogoImage,
        downImg: require('@/assets/images/pages/error.svg'),
      };
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.downImg = require('@/assets/images/pages/error-dark.svg');
          return this.downImg;
        }
        return this.downImg;
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
